import Util from "@/utils/utils";
import { getList, getExport ,getYxList,getStatusList,delLine} from "./api";

export default {
  name: "terminalInspectionRecord",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/storeActivities",
          name: "专卖店市场活动",
          title: "专卖店市场活动"
        }
      ],
      visibleshop:false,
      hideOrShowSize: true,
      columns: [
        {
          label: "分公司",
          prop: "orgName",
          width: 200,
        },
        {
          label: "门店编码",
          prop: "cisCode",
          width: 200,
        },
        {
          label: "门店名称",
          prop: "fullName",
          width: 200,
        },
        {
          label: "专卖店编码",
          prop: "specialCode",
          width: 200,
        },
        {
          label: "行政分类",
          prop: "xZLabel",
          width: 200,
        },
        {
          label: "专卖店类别",
          prop: "specialType",
          width: 200,
        },
        {
          label: "面积",
          prop: "shopSquare",
          width: 200,
        },
        {
          label: "预计专卖店年规模",
          prop: "estimateAnnualScale",
          width: 200,
        },
        {
          label: "客户状态",
          prop: "custStatus",
          width: 200,
        },
        {
          label: "提报人",
          prop: "creator",
          width: 200,
        },
        {
          label: "提报时间",
          prop: "createdTime",
          width: 200,
        },
        {
            label: "修改人1",
            prop: "firstModifier",
            width: 200,
        },
        {
            label: "修改人2",
            prop: "secondModifier",
            width: 200,
        },
        {
          label: "修改时间",
          prop: "modifiedTime",
          width: 200,
        },
        {
          label: "活动主题",
          prop: "activityTheme",
          width: 200,
        },
        {
          label: "活动类别",
          prop: "activityLabel",
          width: 200,
        },
        {
          label: "活动开始时间",
          prop: "startTime",
          width: 200,
        },
        {
          label: "活动结束时间",
          prop: "endTime",
          width: 200,
        },
        {
          label: "活动状态",
          prop: "status",
          width: 200,
        },
        {
          label: "修改次数",
          prop: "modifyCount",
          width: 200,
        },
        {
          label: "结束人",
          prop: "passPerson",
          width: 200,
        },
        {
          label: "结束时间",
          prop: "passTime",
          width: 200,
        },
        {
          label: "活动政策核算销量",
          prop: "salesNum",
          width: 200,
        },
        {
          label: "活动政策核算销额(元)",
          prop: "salesRealPrice",
          width: 200,
        },
        {
          label: "操作",
          slot: "btnClick",
          ellipsis: true,
          fixed:'right',
          width: 150,
        },
      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
        company: "", // 分公司
        cisCode: "", // 门店编码
        fullName: "", // 门店名称
        specialShopCode: "", // 专卖店编码
        startTime: "", // 活动开始时间
        endTime:"", // 活动结束时间
        // creator: "", // 提报人姓名
        firstModifier:'', // 修改人姓名
        secondModifier:'',// 修改人2姓名
        activityTheme: "", // 活动主题
        pageNo: 1,
        pageSize:10,
        activityLabel:'', // 活动类别
        status:'',// 活动状态
        specialShopLevel:'',// 行政fen类别
        specialShopType:'',// 专卖店类别
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      onluyOneMessage: false,
      imgURL:[],
      tableLoading:false,
      monopolyList:[], // 行政分类数据
      monopolyName:'', //行政分类
      shopList:[], // 专卖店类比列表
      shopName:"", // 专卖店类别
      statusList:[], // 活动类别数据
      statusName:'', //活动类别状态
      statusName1:'', //活动 状态数据
      statusList1: [
        {
          id:1,
          name:"未开始"
        },
        {
          id:2,
          name:"进行中"
        },
        {
          id:6,
          name:"待反馈"
        },
        {
          id:3,
          name:"已开展"
        },
        {
          id:4,
          name:"已结束"
        },
        {
          id:5,
          name:"废弃"
        },

      ], // 活动状态数据列别哦
      selectedRowKey:[],
      selectedRowKeys:'',
      rowDta:'',
      mesageInfoTips:'',
      visibleshAbandoned: false,
      marketCenterList:[]
    };
  },

  mounted() {
     this.tablelist();
     // 获取活动类别
     this.getHuodong()
     this.getxingzheng()
     this.getzhuanmaidian()
     this.getType()
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    onGiveType(e){
      this.ssearchList.activityLabel = e.target.value;
    },
    // 删除
    del(row) {
      this.rowDta = ''
      this.visibleshop = true
      this.rowDta = row.id
      this.mesageInfoTips = '确定删除该条数据？'
    },
    delect() {
      let data = {
        ids:this.rowDta 
      }
      this.tableLoading = true
      delLine(data).then(res=> {
        if(res.data.code == 0 ) {
          this.tablelist()
          this.tableLoading = false
        } else {
          this.$message.warning ('删除失败',res.data.msg)
          this.tablelist()
          this.tableLoading = false
        }
      })
    },
    // 废弃
    Abandoned(row){
      this.rowDta = ''
      this.visibleshAbandoned = true
      this.rowDta = row.id
      this.mesageInfoTips = '确定废弃该条数据？'
    },
    scrapData(){
      let data = {
        id:this.rowDta 
      }
      this.tableLoading = true
      // 此处需要调废弃接口 目前还没有
      delLine(data).then(res=> {
        if(res.data.code == 0 ) {
          this.tablelist()
          this.tableLoading = false
        } else {
          this.$message.warning (res.data.msg)
          this.tablelist()
          this.tableLoading = false
        }
      })
    },
    // 选择项更改
    onChange( selRows) {
      this.selectedRowKey= selRows
    },
    // 新增跳转页面() 
    add(row) {
      let routeUrl = this.$router.resolve({
        path: "/storeActivities/addOredit",
      });
      window.open(routeUrl.href, "_blank");
    },
    // jumpEdit
    jumpEdit(row){
      let routeUrl = this.$router.resolve({
        path: "/storeActivities/editOredit",
        query: { 
          id:row.id,
          isCanEditAttachments:row.isCanEditAttachments,
          dataSource:row.dataSource
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 查看
    openView() {
      // 判断是都选中数据
      if(this.selectedRowKey.length <= 0) {
        this.$message.warning('请选择一条数据进行查看')
      } else if (this.selectedRowKey.length > 1) {
        this.$message.warning('只能选择一条数据进行查看')
      } else {
        let routeUrl = this.$router.resolve({
          path: "/storeActivities/activityView",
          query: { id: this.selectedRowKey[0].id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    
    // 获取营专卖类别
    getHuodong() {
      let data  = {
        categoryName:'specialActivityType'
      }
      getYxList(data).then(res=>{
        this.statusList = res.data.data
      })
    },
    // 获取活动类型
    getType() {
      let data  = {
        categoryName:'SPECIAL_ACTIVITY_STATUS'
      }
      getYxList(data).then(res=>{
        this.statusList1 = res.data.data
      })
    },
    // 获取行政分类筛选条件
    getxingzheng() {
      let data  = {
        categoryName:'specialShopLevel'
      }
      getYxList(data).then(res=>{
        this.monopolyList = res.data.data
      })
    },
    getzhuanmaidian() {
      let data  = {
        categoryName:'specialShopType'
      }
      getYxList(data).then(res=>{
        this.marketCenterList = res.data.data
      })
    },
    
    
    // // 专卖店类别数据
    // getStatus() {
    //   let data  = {}
    //   getStatusList(data).then(res=>{
    //     this.statusList = res.data.data
    //   })
    // },
   
   
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    fun_date(num) {
      var date1 = new Date();
      var yuefen =
        date1.getMonth() + 1 < 10
          ? "0" + Number(date1.getMonth() + 1)
          : date1.getMonth();
      var ri =
        date1.getDate() + 1 < 10 ? "0" + date1.getDate() : date1.getDate();
      var time1 = date1.getFullYear() + "-" + yuefen + "-" + ri;
      this.ssearchList.checkEnd = time1;

      var date2 = new Date(date1);
      date2.setDate(date1.getDate() + num);
      var yu =
        date2.getMonth() + 1 < 10
          ? "0" + Number(date2.getMonth() + 1)
          : date2.getMonth();
      var riq =
        date2.getDate() + 1 < 10 ? "0" + date2.getDate() : date2.getDate();
      var time2 = date2.getFullYear() + "-" + yu + "-" + riq;
      this.ssearchList.checkStart = time2;
    },
    exportClick() {
      this.ssearchList.pageNo = 1
      this.ssearchList.pageSize = 1000000
      let data = this.ssearchList;
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "专卖店市场活动列表.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },
    pageSearch(pager) {
      this.ssearchList.pageNo = pager.pageNo;
      this.ssearchList.pageSize = pager.pageSize;
      this.pager.pageNo = pager.pageNo
      this.pager.pageSize = pager.pageSize
      this.tablelist();
    },
    tablelist() {
      let data = this.ssearchList;
      this.tableLoading = true
      getList(data).then(res => {
        if(res.data.code == 0) {
          this.tableData = res.data.data.content;
          if (res.data.data.content) {
            this.searchNumber = res.data.data.totalRows;
            this.pager.count = res.data.data.totalRows;
          }
         this.tableLoading = false
        } else {
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }
      });
    },

    
    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        query: { id: row.id },
      });
      window.open(routeUrl.href, "_blank");
    },

    hideOrShow(data) {
      this.hideOrShowSize = data;
    },
    // 重置
    reset() {
      this.ssearchList = {
        company: "", // 分公司
        cisCode: "", // 门店编码
        fullName: "", // 门店名称
        specialShopCode: "", // 专卖店编码
        startTime: "", // 活动开始时间
        endTime:"", // 活动结束时间
        // creator: "", // 提报人姓名
        firstModifier:'', // 修改人姓名
        secondModifier:'',// 修改人2姓名
        activityTheme: "", // 活动主题
        pageNo: 1,
        pageSize:10,
        activityLabel:'', // 活动类别
        status:'',// 活动状态
        specialShopLevel:'',// 行政fen类别
        specialShopType:'',// 专卖店类别
      };
      this.monopolyName = ""
      this.shopName = ''
      this.statusName='',
      this.statusName1=''
      this.pager.pageNo = 1
      this.pager.pageSize = 10
      this.tablelist();
    },

    // 分页
    pagesiezes(data, pageSize) {
      this.ssearchList.pageNo = data;
      this.ssearchList.pageSize = pageSize;
      this.tablelist();
    },
 
    searchClick() {
      this.tablelist();
    },

    // 筛选条件修该
    // 更改行政分类
    handleChangexingzhengList(value, e){
      this.monopolyName = value ? value : '';
      this.ssearchList. specialShopLevel = e ? e.key : '';
    },
    // 更改专卖店类别
    handleChangezhuanmaiList(value, e){
      this.shopName = value ? value : '';
      this.ssearchList. specialShopType = e ? e.key : '';
    },
     // 更改活动类别
     handleChangestatuspList(value, e) {
      this.statusName = value;
      this.ssearchList.activityLabel = e.key;
    },
     // 更改活动状态
     handleChangestatuspList1(value, e) {
      debugger
      this.statusName1 = value ? value : '';
      this.ssearchList.status = e ? e.key : '';
    },
    // 开始时间更改
    handleTimeChange(e, defaultTime) {

      this.ssearchList.startTime = defaultTime?defaultTime:'';
    },
     // 结束时间更改
     handleendTimeChange(e, defaultTime) {
      this.ssearchList.endTime = defaultTime?defaultTime:'';
    },
  }
};
